import { OmniConnect } from '@bitget-wallet/omni-connect';
import { useEffect, useState } from 'react';

const useOmniConnect = (successConnect: () => void, failConnect: () => void) => {
  const [connector, setConnector] = useState({} as OmniConnect);
  const [isConnected, setIsConnected] = useState(false);
  const init = async (connector: OmniConnect) => {
    await connector.provider.restoreConnection();
  };
  useEffect(() => {
    const connector = new OmniConnect({
      metadata: {
        name: "TabiBot",
        iconUrl: 'https://front.tabibot.com/tabi_icon.webp',
        url: "https://front.tabibot.com",
      },
      namespace: {
        eip155: {
          chains: ['1', '56'],
        },
      },
    });
    setConnector(connector);

    init(connector);

    const subscription = connector.provider.onStatusChange(
      (walletInfo: any) => {
        const { event, connected } = walletInfo;
        switch (event) {
          case "Connect":
            if (connected) {
              setIsConnected(Boolean(connected));
              successConnect();
            }
            break;
          default:
            break;
        }

      },
      (err: any) => {
        const { code, message } = err;
        console.error(`error stream: code: ${code}, message: ${message}`);
        failConnect()
      }
    );
    return () => {
      subscription?.unsubscribe();
    };
  }, []);

  const connect = async () => {
    await connector.provider.connect({
      request: { chainId: '1' },
    });
  };

  return {
    connect,
    isConnected,
  };
};

export default useOmniConnect;
