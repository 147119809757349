import React, { FC, useState } from 'react';
import { useInterval, useRequest, useThrottleFn } from 'ahooks';
import { useSpring, animated } from '@react-spring/web';
import { claim, getMiningInfo, IMiningInfo } from '@/api/game';
import Loading from '@/components/Loading';
import images from '@/const/images';
import dayjs from '@/utils/dayjs';
import { ToastRef } from '@/components/Toast';
import useProfile from '@/hooks/useProfile';
import clsx from 'clsx';
import { useLoading } from '@/components/LoadingProvider';

type Props = {
  toastRef: React.MutableRefObject<ToastRef | undefined>;
};

const CoinProcess: FC<Props> = ({ toastRef }) => {
  const { run } = useProfile();
  const [current, setCurrent] = useState<number>(0); //当前积累coin
  const { setIsLoading } = useLoading();

  const { data: miningInfo, refresh: refreshMiningInfo } = useRequest(
    async () => {
      const miningInfo: IMiningInfo = await getMiningInfo();
      return miningInfo;
    },
    {
      cacheKey: 'miningInfo',
      onBefore: () => {
        if (!miningInfo) {
          setIsLoading(true);
        }
      },
      onError: () => {
        setIsLoading(false);
      },
    }
  );

  const { loading: claimLoading, run: claimRun } = useRequest(
    async () => {
      const value = await claim();
      setCurrent(0);
      refreshMiningInfo();
      run();
      if (value && !!value.zoo_coins) {
        toastRef.current?.show(
          'success',
          `${value.zoo_coins} $zoo claim successfully`
        );
      }
    },
    {
      onError: (error: any) => {
        toastRef.current?.show(
          'error',
          error.message ?? 'Something went wrong'
        );
      },
      manual: true,
    }
  );

  const { run: claimRunThrottle } = useThrottleFn(claimRun, {
    wait: 500,
  });

  useInterval(
    () => {
      if (miningInfo && miningInfo.mining_data) {
        const nextCurrent =
          ((dayjs().unix() -
            dayjs(miningInfo.mining_data.refresh_timestamp / 1000).unix()) *
            miningInfo.mining_data?.rate) /
            3600 +
          miningInfo.mining_data.accumulated +
          miningInfo.mining_data.current;
        setCurrent(
          nextCurrent >= miningInfo.mining_data.top_limit
            ? miningInfo.mining_data.top_limit
            : Number(nextCurrent < 0 ? 0 : nextCurrent.toFixed(4))
        );
        setIsLoading(false);
      }
    },
    1000,
    { immediate: true }
  ); // 每秒计算一下当前current值

  const currentZooValue = useSpring({
    value: current,
  });

  return (
    <div className="w-[315px] shadow-[0px_-3px_0px_0px_#c6dbcd_inset] absolute box-border px-[15px] pt-[5px] pb-[20px] rounded-lg bottom-[20vh] bg-white leading-[20px]">
      <div className="flex justify-between items-center font-changa-one mt-[12px]">
        <div className="flex items-center gap-x-[4px]">
          <img
            className="w-[20px] h-[20px]"
            src={images.header.zooIcon}
            width={20}
            height={20}
          />
          <label className="text-[#266748]">Mining Rate</label>
          <div className="flex items-center px-[8px] gap-[2px] bg-[rgba(255,_97,_33,_0.1)] rounded-[9px_9px_9px_0px]">
            <span className="text-[#FF6121] text-[11px]">
              +{miningInfo?.mining_data.rate}
            </span>
            <img
              className="w-[12px] h-[12px]"
              src={images.header.zooIcon}
              width={12}
              height={12}
            />
            <span className="text-[#FF6121] text-[11px]">/h</span>
          </div>
        </div>
        <div
          className={clsx(
            'w-[66px] h-[26px] shadow-[0px_1px_4px_0px_rgba(0,0,0,0.4),0px_-2px_0px_0px_rgba(0,0,0,0.15)_inset]  rounded-lg  flex justify-center items-center',
            'bg-[#ff6121] cursor-pointer text-white border border-solid border-[rgba(255,255,255,0)]'
          )}
          onClick={claimRunThrottle}
        >
          <span className="mt-[-2px]">
            {claimLoading ? <Loading /> : 'Claim'}
          </span>
        </div>
      </div>
      <div className="w-full relative h-[12px] overflow-hidden rounded-[7px] bg-[#E5D6CC] mt-[8px]">
        <animated.span className="absolute font-changa-one text-[#ffffff] top-[-3.5px] left-[10px] text-[11px] [-webkit-text-stroke-width:1px] [-webkit-text-stroke-color:rgba(255,97,33,1)]">
          {current === miningInfo?.mining_data?.top_limit
            ? miningInfo?.mining_data?.top_limit
            : currentZooValue.value.to((n) => n.toFixed(4))}
        </animated.span>
        <div
          className="h-full rounded-[7px] shadow-[0px_-1.6px_0px_0px_#DB4E14_inset] bg-[#FF6121]"
          style={{
            width: `${(current / Number(miningInfo ? miningInfo.mining_data.top_limit : current)) * 100}%`,
          }}
        ></div>
      </div>
    </div>
  );
};

export default React.memo(CoinProcess);
