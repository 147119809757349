import { forwardRef, useImperativeHandle, useState } from 'react';
import images from '@/const/images';
import clsx from 'clsx';

export interface ToastRef {
  show: (type: string, message: string) => void;
}

const Toast = forwardRef((_, ref) => {
  const [visible, setVisible] = useState(false);
  const [type, setType] = useState<string>('success');
  const [message, setMessage] = useState<string>('');

  useImperativeHandle(ref, () => {
    const show = (type: string, msg: string) => {
      setVisible(true);
      setType(type);
      setMessage(msg);

      // 2s后自动关闭toast
      setTimeout(() => {
        setVisible(false);
        setMessage('');
      }, 2000);
    };

    return { show };
  }, []);

  return (
    <div
      className={clsx(
        visible
          ? 'fixed left-[50%] translate-x-[-50%] bottom-[20vh] z-[999999] mx-auto inline-flex py-[12px] px-[24px] justify-center items-center gap-[5px] rounded-[10px] bg-[rgba(0,_0,_0,_0.90)] [box-shadow:0px]'
          : 'hidden'
      )}
    >
      {type !== 'none' && (
        <img
          className="w-[24px] h-[24px]"
          width={24}
          height={24}
          src={type === 'success' ? images.common.success : images.common.error}
          alt=""
        />
      )}
      <span className="text-[14px] text-white font-changa-one whitespace-pre">
        {message}
      </span>
    </div>
  );
});

export default Toast;
