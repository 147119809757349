import { useCallback, useRef } from 'react';
import { TonAdPopupShow, TonAdProps } from 'ton-ai-sdk';

export interface TonAiAdParams {
  onAdComplete: (ad?: TonAdProps) => void;
  onAdError: (error?: any) => void;
  onAdClose?: () => void;
}

export default function useTonAiAd({
  onAdComplete,
  onAdError,
  onAdClose,
}: TonAiAdParams) {
  const isCompleteRef = useRef(false);

  return useCallback(() => {
    TonAdPopupShow({
      blockId: import.meta.env.VITE_TON_BLOCK_ID,
      onAdClose: () => {
        // 用户触发了广告跳转
        if (isCompleteRef.current) {
          isCompleteRef.current = false;
          return;
        }
        onAdClose?.();
      },
      onAdComplete: (ad) => {
        // 用户观看广告完成
        isCompleteRef.current = true;
        onAdComplete(ad);
      },
      onAdError: (error) => {
        // 用户在播放广告时遇到错误
        onAdError(error);
      },
    });
  }, [onAdError, onAdComplete, onAdClose]);
}
