import { useMemo, useRef } from 'react';
import images from '@/const/images';
import dayjs from '@/utils/dayjs';
import { useLocalStorageState, useMount } from 'ahooks';
import { useNavigate } from 'react-router-dom';
import Header from '@/components/Header';
import { ModalRef } from '@/components/Modal';
import Toast, { ToastRef } from '@/components/Toast';
import useProfile from '@/hooks/useProfile';
import JoinCommunityModal, {
  JoinCommunityModalRef,
} from './joinCommunityModal';
import logEvent, { EventName } from '@/utils/firebase';
import CoinProcess from './CoinProcess';
import ExtendsAModal from './ExtendsA';
import ExtendsBModal from './ExtendsB';
import VolumeModal from '@/components/Header/VolumeModal';
import useLevelConfig from '@/hooks/useLevelConfig';
import BeforeLevel3Process from './BeforeLevel3Process';
import Process from './Process';
// import { useActiveAccount, useConnect } from 'thirdweb/react';
// import { inAppWallet } from 'thirdweb/wallets';
// import { thirdWebClient } from '@/thirdweb';
// import { signMessage } from 'thirdweb/utils';

export default function Index() {
  const navigate = useNavigate();
  const { userProfile } = useProfile();

  const { level, isFinalLevel } = useLevelConfig(userProfile);
  const toastRef = useRef<ToastRef>();
  const extendsBModal = useRef<ModalRef>();
  const extendsAModal = useRef<ModalRef>();
  const volumeModal = useRef<ModalRef>();
  const joinCommunityModalRef = useRef<JoinCommunityModalRef>();
  const [isShowJoinCommunityModal] = useLocalStorageState<boolean>(
    'isShowJoinCommunityModal',
    {
      defaultValue: true,
    }
  );

  const [isShowExtendsAModal, setIsShowExtendsAModal] =
    useLocalStorageState<boolean>('isShowExtendsAModal', {
      defaultValue: true,
    });

  const isTodayCreateTime = useMemo(() => {
    if (!userProfile) return false;
    return (
      dayjs.utc(userProfile?.user?.create_time).format('YYYY-MM-DD') ===
      dayjs.utc().format('YYYY-MM-DD')
    );
  }, [userProfile?.user]);

  // 是否可升级
  const isUpgrade = useMemo(() => {
    if (isFinalLevel) return false;
    if (!userProfile) return false;
    if (!level) return false;
    return Boolean(
      userProfile?.user.coins &&
        Math.abs(Number(level?.level_up_coin)) <= userProfile?.user.coins
    );
  }, [userProfile?.user, level]);

  const handleHide = () => {
    setIsShowExtendsAModal(false);
    extendsAModal.current?.hide();
    extendsBModal.current?.show();
  };

  useMount(() => {
    if (isTodayCreateTime && isShowJoinCommunityModal) {
      joinCommunityModalRef.current?.show();
    }

    if (!isTodayCreateTime && isShowExtendsAModal) {
      extendsAModal.current?.show();
    }
  });

  // const { connect } = useConnect();
  // const account = useActiveAccount();

  // console.log(account);

  // const handleLogin = async () => {
  //   await connect(async () => {
  //     const wallet = inAppWallet();

  //     await wallet.connect({
  //       client: thirdWebClient,
  //       strategy: 'telegram',
  //     });

  //     return wallet;
  //   });

  //   await signMessage({
  //     message: 'Hello, world!',
  //     account: account!,
  //   });
  // };

  return (
    <div className="w-screen h-screen bg-[#e4f8cf]">
      <Toast ref={toastRef} />

      <ExtendsAModal extendsAModal={extendsAModal} onHide={handleHide} />
      <ExtendsBModal
        extendsBModal={extendsBModal}
        userProfile={userProfile}
        level={level}
      />
      <JoinCommunityModal ref={joinCommunityModalRef} />
      <VolumeModal volumeModal={volumeModal} />

      <div className="w-full h-full fixed flex justify-center items-center left-0 top-0 bg-[url('@/assets/images/game/bg.png')] bg-cover bg-top bg-no-repeat">
        <div className="absolute bottom-[41vh] text-center">
          <div
            className="w-[94px] cursor-pointer h-[28px] flex-shrink-0 rounded-[8px] bg-[rgba(0,_0,_0,_0.3)] stroke-[1px] stroke-[rgba(0,_0,_0,_0.04)] shadow-[0px_-2px_0px_0px_rgba(0,0,0,0.10)_inset] [filter:drop-shadow(0px_1px_4px_rgba(0,0,0,0.40))] backdrop-blur-sm mx-auto relative flex items-center justify-center"
            onClick={() => {
              navigate('/upgrade');
              logEvent(EventName.SHIRO_UPGRADE_CLICK, {
                enter_upgrade: 'upgrade',
              });
            }}
          >
            <img
              className="w-[45px] h-[45px] absolute left-[-23px]"
              src={images.game.levelIcon}
            />
            <div className="flex flex-col items-start pb-[4px]">
              <span className="text-white font-changa-one italic text-[12px]">
                Lv.{userProfile?.user.level}
              </span>
              <Process level={level} />
            </div>
            {isUpgrade && (
              <img
                className="w-[28px] h-[28px] absolute right-[-13px]"
                src={images.game.upgradeBtn}
              />
            )}
          </div>
          <img
            className="w-[240px]"
            width={360}
            height={203}
            src={images.common.symbol}
            // onClick={handleLogin}
          />
        </div>

        <Header volumeModal={volumeModal} />

        <div className="absolute right-2.5 top-[12vh] flex flex-col">
          <img
            className="w-[50px] cursor-pointer"
            src={images.game.inviteIcon}
            onClick={() => {
              navigate('/invite');
              logEvent(EventName.SHIRO_BUTTON_CLICK, {
                btn_name: `invite`,
              });
            }}
          />
        </div>
        {userProfile?.user.level && userProfile?.user.level >= 3 ? (
          <CoinProcess toastRef={toastRef} />
        ) : (
          <BeforeLevel3Process />
        )}
      </div>
    </div>
  );
}
