import { useState } from 'react';
import { FixedLayout } from '@telegram-apps/telegram-ui';
import { useRequest } from 'ahooks';
import clsx from 'clsx';
import store from 'store';
import { useLocation, useNavigate } from 'react-router-dom';
import { getRewardProjects, Status, TaskType } from '@/api/mine';
import { getTaskList } from '@/api/task';
import images from '@/const/images';
import useIsSpin from '@/hooks/useIsSpin';
import logEvent, { EventName } from '@/utils/firebase';

interface Tabbar {
  name: string;
  path: string;
  icon: string;
  selectedIcon: string;
  round?: boolean;
}

const tabbarList: Tabbar[] = [
  {
    name: 'Shiro',
    path: '/index',
    icon: images.tabbar.gameIcon,
    selectedIcon: images.tabbar.gameSelectedIcon,
  },
  {
    name: 'Earn',
    path: '/invite',
    icon: images.tabbar.earnIcon,
    selectedIcon: images.tabbar.earnSelectedIcon,
  },
  {
    name: 'Spin',
    path: '/spin',
    round: true,
    icon: images.tabbar.spinIcon,
    selectedIcon: images.tabbar.spinSelectedIcon,
  },
  {
    name: 'Task',
    path: '/task',
    icon: images.tabbar.taskIcon,
    selectedIcon: images.tabbar.taskSelectedIcon,
  },
  {
    name: 'Mine',
    path: '/mine',
    icon: images.tabbar.mineIcon,
    selectedIcon: images.tabbar.mineSelectedIcon,
  },
];

const Tabbar = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const { isSpin } = useIsSpin();

  const handleLink = (tabbar: Tabbar) => {
    if (location.pathname === tabbar.path) return;
    navigate(tabbar.path);
    logEvent(EventName.GLOBAL_MAIN_TAB_CLICK, {
      tab_name: `${tabbar.name}`,
    });
  };

  const [showDot, setShowDot] = useState(false);
  const [showNew, setShowNew] = useState(false);
  const [showTaskDot, setShowTaskDot] = useState(false);

  useRequest(getRewardProjects, {
    onSuccess: (data) => {
      const projectNames = data.map((item) => item.project_name);
      if (store.get('rewardProject')) {
        const hasNew = !!projectNames?.find(
          (item) => !store.get('rewardProject').includes(item)
        );
        if (hasNew) {
          setShowNew(true);
          store.set('rewardProject', projectNames);
        }
      } else {
        store.set('rewardProject', projectNames);
        setShowNew(true);
      }

      setShowDot(
        !!data?.find((item) => item.user_project_status === Status.NotFinish)
      );
    },
    cacheKey: 'rewardProjects',
  });

  useRequest(getTaskList, {
    onSuccess: (data) => {
      let showTaskDot = false;

      data?.forEach((item) => {
        item.task_list?.forEach((i) => {
          if (
            i.user_task_status === Status.NotFinish &&
            i.task_type !== TaskType.Invite3Person
          ) {
            showTaskDot = true;
          }
        });
      });

      setShowTaskDot(showTaskDot);
    },
    cacheKey: 'taskList',
  });

  return (
    <FixedLayout
      vertical="bottom"
      className="w-full bg-[linear-gradient(_to_bottom,_transparent,_rgba(255,255,255,1)_)] bg-[100%_100%]"
    >
      <div className="flex justify-between items-center w-[339px] h-[86px] bg-[url('@/assets/images/tabbar/bg.png')] bg-cover bg-bottom mx-auto mb-5 px-[3px] text-[#808080] font-rubik pt-[10px]">
        {tabbarList?.map((tabbar) => (
          <div
            key={tabbar.name}
            className={clsx(
              'w-[80px] cursor-pointer h-16 flex flex-col justify-center gap-[5px] items-center text-[10px] rounded-2xl',
              location.pathname === tabbar.path && 'text-[#ff5c01]'
            )}
            onClick={() => {
              handleLink(tabbar);
              tabbar.name === tabbarList[4].name && setShowNew(false);
            }}
          >
            <div
              className={clsx(
                'relative flex flex-col justify-center items-center',
                tabbar.round &&
                  "w-[64px] h-[64px] rounded-[50%] mt-[-20px] bg-[url('@/assets/images/tabbar/round_bg.svg')] bg-cover pt-[4px]",
                tabbar.round &&
                  location.pathname === tabbar.path &&
                  "bg-[url('@/assets/images/tabbar/round_selected_bg.svg')]"
              )}
            >
              {tabbar.round ? (
                <img
                  className="w-[42px]"
                  width={42}
                  height={42}
                  src={
                    location.pathname === tabbar.path
                      ? tabbar.selectedIcon
                      : tabbar.icon
                  }
                />
              ) : (
                <img
                  className="w-[26px]"
                  width={26}
                  height={26}
                  src={
                    location.pathname === tabbar.path
                      ? tabbar.selectedIcon
                      : tabbar.icon
                  }
                />
              )}
              {tabbar.name}
              {showDot && !showNew && tabbar.name === tabbarList[4].name && (
                <div className="w-[8px] h-[8px] bg-[#FF5C01] [filter:drop-shadow(0.4px_0.4px_0px_#A13C04)] absolute right-0 top-0 translate-x-1/2 -translate-y-1/2 rounded-full"></div>
              )}
              {showNew && tabbar.name === tabbarList[4].name && (
                <div className="w-[22px] py-[1.5px] shrink-0 [background:#F1322E] shadow-[0px_0.4px_1px_0px_rgba(0,0,0,0.34),0px_-1.2px_0px_0px_rgba(0,0,0,0.13)_inset] rounded-[6px_6px_6px_0px] absolute right-0 top-0 translate-x-2/3 -translate-y-1/2 text-white text-center font-changa-one text-[8px] font-normal leading-[10px] tracking-[-0.408px] capitalize">
                  New
                </div>
              )}

              {tabbar.name === tabbarList[1].name && (
                <img
                  className="w-[10px] h-[10px] absolute right-[-3px] top-[-3px]"
                  src={images.tabbar.earnDotIcon}
                  alt=""
                />
              )}

              {isSpin && tabbar.name === tabbarList[2].name && (
                <div className="w-[8px] h-[8px] bg-[#FF5C01] [filter:drop-shadow(0.4px_0.4px_0px_#A13C04)] absolute right-[11px] top-[6px] translate-x-1/2 -translate-y-1/2 rounded-full"></div>
              )}

              {showTaskDot && tabbar.name === tabbarList[3].name && (
                <div className="w-[8px] h-[8px] bg-[#FF5C01] [filter:drop-shadow(0.4px_0.4px_0px_#A13C04)] absolute right-0 top-0 translate-x-1/2 -translate-y-1/2 rounded-full"></div>
              )}
            </div>
          </div>
        ))}
      </div>
    </FixedLayout>
  );
};

export default Tabbar;
