import { useRef, useState, useCallback } from 'react';
import { Status, SubTaskEntity, TaskType } from '@/api/mine';
import { getTaskList } from '@/api/task';
import images from '@/const/images';
import { useMount, useRequest, useLocalStorageState } from 'ahooks';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { postEvent } from '@telegram-apps/sdk-react';
import { nanoid } from 'nanoid';
import { useAdsgram } from '@/hooks/useAdsgram';
import { reportAD, ReportStatus } from '@/api/game';
import { verifyTask } from '@/api/task';

import { ModalRef } from '@/components/Modal';
import CheckInModal, { CheckInModalRef } from '@/components/Task/CheckInModal';
import Content from '@/components/Task/Content';
import TaskModal from '@/components/Task/TaskModal';
import TaskItem from '@/components/TaskItem';
import Toast, { ToastRef } from '@/components/Toast';
import Loading from '@/components/Loading';
import CountDownTimer from '@/components/CountDownTimer';
import ClaimModal, { ClaimModalRef } from '@/components/Task/ClaimModal';
import dayjs from '@/utils/dayjs';
import useProfile from '@/hooks/useProfile';
import TaskLogEvent from './taskFirebase';
import ImageWithDefault from '@/components/ImageWithDefault';
import { useLoading } from '@/components/LoadingProvider';

export default function Task() {
  const [searchParams, setSearchParams] = useSearchParams();
  const navigate = useNavigate();

  const taskModalRef = useRef<ModalRef>();
  const checkInModalRef = useRef<CheckInModalRef>();
  const claimModalRef = useRef<ClaimModalRef>();
  const { setIsLoading } = useLoading();

  const toastRef = useRef<ToastRef>();

  const { run } = useProfile();

  useMount(() => {
    if (searchParams.get('checkIn')) {
      checkInModalRef.current?.show();
      setSearchParams();
    }
  });

  const { data, refresh } = useRequest(getTaskList, {
    cacheKey: 'taskList',
    onBefore: () => {
      if (!data) {
        setIsLoading(true);
      }
    },
    onFinally: () => {
      setIsLoading(false);
    },
  });

  const [task, setTask] = useState<SubTaskEntity | null>(null);

  const [isWatchAd, setIsWatchAd] = useLocalStorageState<boolean>(
    'task_isWatchAd',
    {
      defaultValue: false,
    }
  );
  const [adId, setAdId] = useLocalStorageState<string>('task_adId', {
    defaultValue: '',
  });

  const onReward = useCallback(async (id: string) => {
    const res = await reportAD({
      status: ReportStatus.Done,
      ad_id: id,
      from: 'task',
    });

    if (res.code !== 200) {
      toastRef.current?.show('error', res.message || 'Report Error');
      return;
    }

    setIsWatchAd(true);
  }, []);

  const onError = useCallback(() => {
    toastRef.current?.show('error', 'AD Error!');
    setIsWatchAd(false);
    setAdId();
  }, []);

  const showAd = useAdsgram({
    onReward,
    onError,
  });

  const { loading: showAdLoading, run: runShowAd } = useRequest(
    async () => {
      const id = nanoid(5);
      const res = await reportAD({
        status: ReportStatus.Tap,
        ad_id: id,
        from: 'task',
      });

      if (res.code !== 200) {
        toastRef.current?.show('error', res.message || 'Report Error');
        return;
      }

      setAdId(id);
      await showAd(id);
    },
    { manual: true }
  );

  const { run: verifyTaskRun, loading: verifyTaskLoading } = useRequest(
    async (task: SubTaskEntity) => {
      try {
        const res = await verifyTask({ task_tag: task.task_tag, ad_id: adId });

        if (res.code !== 200 || !res.data?.verify) {
          toastRef.current?.show('error', res.message || 'Check Error');
          return;
        }

        claimModalRef?.current?.show(task);
      } catch (err: any) {
        toastRef.current?.show('error', err.message || 'Check Error');
      } finally {
        run();
        refresh();
        setIsWatchAd(false);
        setAdId('');
      }
    },
    { manual: true }
  );

  return (
    <div className="min-h-screen pb-[120px] px-[20px] relative [background:linear-gradient(180deg,#D9DBFF,#F2F6FF)]">
      <img
        className="w-full absolute top-0 left-0 z-0 h-full"
        src={images.task.bg2}
      />

      <img
        className="m-auto w-[217px] relative z-0"
        src={images.task.banner}
        width={217}
        height={199}
      />

      <div className="flex flex-col gap-[18px] mt-[5px] relative z-0">
        {(data?.length || 0) > 0 ? (
          data?.map?.((item) => (
            <Content title={item.project_name} key={item.project_tag}>
              {(item?.task_list || []).map((task) => (
                <TaskItem
                  onClick={() => {
                    TaskLogEvent(task);
                    if (showAdLoading || verifyTaskLoading) {
                      return;
                    }

                    if (task.task_type == TaskType.FrontCheckIn) {
                      checkInModalRef.current?.show();
                      return;
                    }

                    if (task.task_type === TaskType.JumpProject) {
                      navigate(`/mine/project/${task.link_url}`, {
                        state: location.pathname,
                      });
                      return;
                    }

                    if (
                      [
                        TaskType.Invite3Person,
                        TaskType.PostStory,
                        TaskType.LaunchOKX,
                      ].includes(task.task_type) &&
                      task.user_task_status === Status.Success
                    ) {
                      return;
                    }

                    if (task.task_type === TaskType.WatchAds) {
                      if (
                        task.user_task_status === Status.NotFinish &&
                        !isWatchAd &&
                        dayjs.utc() >= dayjs.utc(task?.watch_ads_cold_time)
                      ) {
                        runShowAd();
                      }
                      return;
                    }

                    if (task.user_task_status === Status.Success) {
                      if (task?.task_type === 'web_pass') {
                        postEvent('web_app_open_link', { url: task?.link_url });
                        return;
                      }

                      postEvent('web_app_open_tg_link', {
                        path_full: task?.link_url,
                      });
                      return;
                    }

                    taskModalRef.current?.show();
                    setTask(task);
                  }}
                  key={task.task_tag}
                >
                  <ImageWithDefault
                    className="w-[36px] h-[36px] rounded-[5px]"
                    src={task.img_url}
                    width={36}
                    height={36}
                  />
                  <div className="mx-[12px] flex flex-col gap-[9px] flex-1">
                    <h4 className="text-black font-changa-one text-sm leading-[14px] capitalize">
                      {task.task_name}
                    </h4>
                    <div className="text-orange-600 flex items-center font-changa-one text-sm leading-[14px] capitalize">
                      <img
                        className="w-[16px] h-[16px] mt-[2px]"
                        src={images.common.coin}
                        width={16}
                        height={16}
                      />
                      +{task.reward}
                    </div>
                  </div>
                  {/* 广告任务单独判断按钮状态 */}
                  {task?.task_type === TaskType.WatchAds ? (
                    <>
                      {task?.user_task_status === Status.NotFinish ? (
                        <>
                          {dayjs.utc() >=
                          dayjs.utc(task?.watch_ads_cold_time) ? (
                            <>
                              {isWatchAd ? (
                                <div
                                  className="w-[61px] h-[30px] shrink-0 opacity-[var(--sds-size-stroke-border)] bg-[#00CD00] shadow-[0px_0.8px_1px_0px_#00000057,0px_-1px_0px_1px_#00000021_inset] rounded-[47px] flex justify-center items-center font-changa-one text-white"
                                  onClick={() => verifyTaskRun(task)}
                                >
                                  {verifyTaskLoading ? <Loading /> : 'Claim'}
                                </div>
                              ) : (
                                <img
                                  className="w-[30px] h-[30px] ml-auto shrink-0 grow-0"
                                  src={images.task.linkBtn}
                                  width={30}
                                  height={30}
                                />
                              )}
                            </>
                          ) : (
                            <div className="text-center">
                              <h3 className="font-changa-one text-[12px] text-[#B0B0B0]">
                                <CountDownTimer
                                  targetDate={dayjs
                                    .utc(task.watch_ads_cold_time)
                                    .format()}
                                  onEnd={() => refresh()}
                                />
                              </h3>
                              <p className="text-[10px] text-[#B0B0B0]">
                                New Rewards
                              </p>
                            </div>
                          )}
                        </>
                      ) : (
                        <img
                          className="w-[30px] h-[30px] ml-auto shrink-0 grow-0"
                          src={images.task.completedIcon}
                          width={30}
                          height={30}
                        />
                      )}
                    </>
                  ) : (
                    <img
                      className="w-[30px] h-[30px] ml-auto shrink-0 grow-0"
                      src={
                        task.user_task_status === Status.NotFinish
                          ? images.task.linkBtn
                          : images.task.completedIcon
                      }
                      width={30}
                      height={30}
                    />
                  )}
                </TaskItem>
              ))}
            </Content>
          ))
        ) : (
          <div className="flex flex-col items-center gap-[8px] pt-[60px]">
            <img
              src={images.mine.empty}
              alt=""
              width={120}
              height={120}
              className="w-[120px]"
            />

            <div className="text-[#C1C1C1] font-changa-one text-sm leading-[14px] capitalize">
              No task yet
            </div>
          </div>
        )}
      </div>

      <TaskModal modalRef={taskModalRef} task={task!} refresh={refresh} />

      <CheckInModal ref={checkInModalRef} refresh={refresh} />

      <ClaimModal ref={claimModalRef} />

      <Toast ref={toastRef}></Toast>
    </div>
  );
}
