import Modal, { ModalRef } from '@/components/Modal';
import images from '@/const/images';
import React, { FC } from 'react';
import { useNavigate } from 'react-router-dom';

type Props = {
  getFreeEnergyModalRef: React.MutableRefObject<ModalRef | undefined>;
  onClose: () => void;
  onAfterNavigate: () => void;
};
const GetFreeEnergyModal: FC<Props> = ({
  getFreeEnergyModalRef,
  onClose,
  onAfterNavigate,
}) => {
  const navigate = useNavigate();

  return (
    <Modal
      ref={getFreeEnergyModalRef}
      icon={images.task.modalIcon1}
      onClose={onClose}
    >
      <div className="w-[339px] pb-[20px] shrink-0 [background:linear-gradient(180deg,rgba(37,1,255,0.17)_0%,rgba(255,255,255,0)_100%),#FFF] shadow-[0px_2px_0px_0px_rgba(255,255,255,0.99)_inset,0px_1px_12px_0px_rgba(0,0,0,0.40),0px_-5px_0px_0px_#DCDCDC_inset] rounded-[20px] pt-[60px] text-center">
        <h3 className="text-xl font-changa-one text-black">Get Free Energy</h3>
        <p className="font-poppins text-slate-400 w-[260px] mx-auto">
          +10 for each friend invited
        </p>
        <div className="flex items-center justify-center w-[141px] h-[54px] my-[10px] mx-auto bg-[#F2F0FA] border-[1.4px] border-solid border-[#EAE7F2] rounded-[10px] [box-shadow:inset_0px_3px_0px_0px_rgba(0,0,0,0.03)]">
          <img
            src={images.game.spin.energyIcon}
            alt=""
            className="w-[26px] h-[26px]"
          />
          <span className="text-[24px] font-changa-one text-black">+10</span>
        </div>
        <div
          className="flex w-[291px] h-[46px] cursor-pointer justify-center items-center gap-2.5 shrink-0 [background:#FD6019] shadow-[0px_-2.8px_0px_1px_rgba(0,0,0,0.13)_inset,0px_0.8px_1px_0px_rgba(0,0,0,0.34)]  rounded-[47px] mx-auto text-white mt-[30px] text-nowrap font-bold font-changa-one"
          onClick={() => {
            onAfterNavigate();
            navigate('/invite');
          }}
        >
          Invite A Friend
        </div>
      </div>
    </Modal>
  );
};

export default React.memo(GetFreeEnergyModal);
