/*
 * Created Date: August 27th 2024, 11:13:31 am
 * Author: zhoupengcheng
 * -----
 * Last Modified: August 29th 2024, 11:31:45 am
 */

import { useCallback, useEffect } from 'react';
/**
 * Check Typescript section
 * and use your path to adsgram types
 */

export enum EventType {
  AdError,
  AdClick,
  AdComplete,
  AdClose,
  ExceededDailyLimit,
  AdNotPlay,
  NoAd,
}

export interface useAdsgramParams {
  onReward: () => void;
  onError?: () => void;
  onClose?: () => void;
}

export function useAdStar({ onReward, onError, onClose }: useAdsgramParams) {
  useEffect(() => {
    try {
      (window as any).AdStar.init({
        blockToken: import.meta.env.VITE_BLOCK_TOKEN,
      });
    } catch (error) {
      try {
        (window as any).AdStar.init({
          blockToken: import.meta.env.VITE_BLOCK_TOKEN,
        });
      } catch (error) {
        console.warn(error)
      }
    }
  }, []);

  return useCallback(() => {
    (window as any).AdStar.showDialogAd((result: EventType, err: any) => {
      // If the ad plays normally
      if (!err) {
        // User clicked on the ad
        if (result == EventType.AdClick) {
          // You can give out rewards here
        }
        // Countdown ends, ad closes automatically
        else if (result == EventType.AdComplete) {
          onReward();
        }
        // User closed the ad
        else if (result == EventType.AdClose) {
          onClose?.();
        }
      } else {
        onError?.();
      }
    });
  }, [onReward, onClose, onError]);
}
