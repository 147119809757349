import { Suspense, useCallback, useMemo, useRef, useState } from 'react';
import { useLocalStorageState, useRequest, useUpdateEffect } from 'ahooks';
import clsx from 'clsx';
import { motion, useAnimation } from 'framer-motion';
import Toast, { ToastRef } from '@/components/Toast';
import { ModalRef } from '@/components/Modal';
import Header from '@/components/Header';
import images from '@/const/images';
import {
  ADProvider,
  getSpinWatchADStatus,
  IEnergy,
  ISpinInfo,
  ISpinPlayRecord,
  ISpinReport,
  MultiplierTypeEnum,
  spinInfo,
  spinPlay,
  spinReport,
} from '@/api/game';
import { formatCurrency } from '@/utils';
import useSpinResultAnimate from '@/hooks/useSpinResultAnimate';
import SpinToast, { SpinToastRef } from './SpinToast';
import SpinItem from './SpinItem';
import dynamicSpinIcons from './config';
import useIsApp from '@/hooks/useIsApp';
import GetFreeEnergyModal from './GetFreeEnergyModal';
import EnergyInterval from './EnergyInterval';
import logEvent, { EventName } from '@/utils/firebase';
import useProfile from '@/hooks/useProfile';
import VolumeModal from '@/components/Header/VolumeModal';
import useVolumeSound from '@/hooks/useVolumeSound';
import useTriggerHaptic from '@/hooks/useTriggerHaptic';
import lazyWithPreload from '@/utils/lazy';
import WatchAdModal from './WatchAdModal';
import Broadcasting from './Broadcasting';

const SpinAnimation = lazyWithPreload(() => import('./SpinAnimation'));
SpinAnimation.preload();

interface SpinAnimationRef {
  show: (emote: number, time: number) => void;
}
export default function Spin() {
  // 刷新路由loader数据
  const { run, userProfile } = useProfile();

  const isApp = useIsApp();

  const spinToastRef = useRef<SpinToastRef>();
  const toastRef = useRef<ToastRef>();

  const getFreeEnergyModalRef = useRef<ModalRef>();
  const watchAdModalRef = useRef<ModalRef>();
  const broadcastingRef = useRef<ModalRef>();

  const volumeModal = useRef<ModalRef>();

  const spinAnimationRef = useRef<SpinAnimationRef>();

  const [isSpins, setIsSpins] = useState<boolean[]>([false, false, false]);

  const [isPressed, setIsPressed] = useState<boolean>(false);

  const [isBet, setIsBet] = useState<boolean>(false);

  const [multiplier, setMultiplier] = useLocalStorageState<MultiplierTypeEnum>(
    'multiplier',
    {
      defaultValue: MultiplierTypeEnum.One,
    }
  );

  const [currentNos, setCurrentNos] = useState<number[]>([]);

  const [energy, setEnergy] = useLocalStorageState<IEnergy>('energy', {
    defaultValue: undefined,
  });

  const [isClaim, setIsClaim] = useLocalStorageState('rewardClaim', {
    defaultValue: false,
  });

  const [openAd, setOpenAd] = useLocalStorageState('openAd', {
    defaultValue: false,
  });

  const throttleRef = useRef<boolean>(false);

  const { run: runSpinResultAnimate } = useSpinResultAnimate();

  const controls = useAnimation();
  const { triggerHaptic } = useTriggerHaptic();

  const { playAudio: playBetAudio } = useVolumeSound('/audio/spin/bet.mp3');
  const { playAudio: playSpinAudio } = useVolumeSound('/audio/spin/spin.mp3');

  const {
    bg1,
    bg2,
    bg3,
    arrowLeftIcon,
    arrowRightIcon,
    betIcon,
    betPressedIcon,
    handle,
    handle2,
    holes,
    lightLeftIcon,
    lightRightIcon,
    pedestal,
    plantLeft,
    plantRight,
    spinBtn,
    spinBtnPressed,
    slot,
  } = useMemo(() => images.game.spin, []);

  const [coins, setCoins] = useState<{
    coin: number;
    index: number;
  }>({
    coin: 0,
    index: 0,
  });

  const { data: status, refresh: watchADStatusRefresh } = useRequest(
    async () => {
      const res = await getSpinWatchADStatus();
      return res;
    },
    {
      cacheKey: 'watchADStatus',
    }
  );

  const handleClose = () => {
    getFreeEnergyModalRef.current?.hide();
    if ((status?.count ?? 0) < 10) {
      watchAdModalRef.current?.show();
    }
  };

  const handleAfterNavigate = () => {
    setOpenAd(true);
  };

  const { data, refresh: refreshSpinInfo } = useRequest(
    async () => {
      const result: ISpinInfo = await spinInfo();
      setEnergy(result.energy);
      return result;
    },
    {
      cacheKey: 'spinInfo',
    }
  );

  const { runAsync: spinPlayRun } = useRequest(
    async () => {
      const payload = await spinPlay({
        multiplier: multiplier || MultiplierTypeEnum.One,
      });
      setEnergy(payload.energy);
      return payload;
    },
    {
      manual: true,
    }
  );
  const { run: runReport } = useRequest(
    async (params: ISpinReport) => {
      await spinReport(params);
      refreshSpinInfo();
      watchADStatusRefresh();
    },
    {
      manual: true,
    }
  );

  const hasPlay = useMemo(() => {
    return (energy?.energy || 0) >= (multiplier || MultiplierTypeEnum.One);
  }, [energy?.energy, multiplier]);

  const handleMultiple = () => {
    try {
      logEvent(EventName.SLOT_BUTTON_CLICK, {
        btn_name: 'bet',
      });
    } catch (error) {
    } finally {
      setIsBet(true);
      playBetAudio();

      // 不能在setMultiplier传入回调中spinToastRef.current.show，避免在渲染时更新另一个组件的ref
      const currentMulti = multiplier || MultiplierTypeEnum.One;
      const nextMultiplier =
        currentMulti + 1 > MultiplierTypeEnum.Three
          ? MultiplierTypeEnum.One
          : currentMulti + 1;
      setMultiplier(nextMultiplier);
      spinToastRef.current?.show(nextMultiplier);
      setTimeout(() => {
        setIsBet(false);
      }, 100);
    }
  };
  const handleAnimation = async ({
    rule_id,
    amount,
    multiplier,
  }: ISpinPlayRecord['prize']) => {
    const { result, i, emote, time } = dynamicSpinIcons(rule_id);
    setCurrentNos(result);
    setIsSpins([false, true, true]);
    triggerHaptic({
      type: 'impact',
      impact_style: 'medium',
    });

    setTimeout(() => {
      setIsSpins([false, false, true]);
      triggerHaptic({
        type: 'impact',
        impact_style: 'medium',
      });
    }, 300);
    // 第三列动画结束处理逻辑
    setTimeout(() => {
      throttleRef.current = false;
      setIsSpins([false, false, false]);
      triggerHaptic({
        type: 'notification',
        notification_type: 'error',
      });
      spinAnimationRef.current?.show(emote, time);
      setCoins({
        coin: amount * multiplier,
        index: i,
      });
      runSpinResultAnimate(rule_id);
      run();
      handleArrowAnimateEnd();
    }, 600);
  };

  const handleSpin = async () => {
    try {
      logEvent(EventName.SLOT_BUTTON_CLICK, {
        btn_name: 'spin',
      });
    } catch (error) {
    } finally {
      if (throttleRef.current) return;
      if (!hasPlay) {
        if (
          (userProfile?.user.invites ?? 0) >= 20 &&
          (status?.count ?? 0) >= 10
        ) {
          toastRef.current?.show('error', 'You have no enough energy');
          return;
        }
        if (openAd) {
          handleClose();
        } else {
          (userProfile?.user.invites ?? 0) < 20 &&
            getFreeEnergyModalRef.current?.show();
        }
        return;
      }
      throttleRef.current = true;
      setIsPressed(true);
      setIsSpins([true, false, false]);
      try {
        playSpinAudio();
        triggerHaptic({
          type: 'impact',
          impact_style: 'heavy',
        });

        handleArrowAnimateStart();

        setTimeout(() => {
          setIsSpins([true, true, false]);
          triggerHaptic({
            type: 'impact',
            impact_style: 'medium',
          });
        }, 250);
        setTimeout(() => {
          setIsPressed(false);
          setIsSpins([true, true, true]);
          triggerHaptic({
            type: 'impact',
            impact_style: 'medium',
          });
        }, 500);
        const playResult = await spinPlayRun();
        setTimeout(() => {
          handleAnimation(playResult.prize);
        }, 1000);
      } catch (error: any) {
        throttleRef.current = false;
        setCurrentNos([]);
        handleArrowAnimateEnd();
        setTimeout(() => {
          setIsSpins([false, false, false]);
          toastRef.current?.show(
            'error',
            error.message ?? 'Something went wrong'
          );
        }, 600);
      }
    }
  };

  const handleArrowAnimateStart = () => {
    controls.start({
      rotate: [0, -30, 30, -30, 0], // 左右各30度旋转
      transition: {
        duration: 0.15,
        repeat: Infinity,
        repeatType: 'reverse',
        ease: 'easeInOut',
      },
    });
  };

  const handleArrowAnimateEnd = () => {
    controls.stop(); // 停止动画
    controls.set({ rotate: 0 });
  };

  const onAdComplete = useCallback(
    (provider: ADProvider, ad: Omit<ISpinReport, 'ad_provider'>) => {
      runReport({
        ...ad,
        ad_provider: provider,
      });
      toastRef.current?.show('success', 'spin +5');
    },
    []
  );

  const onAdError = useCallback(() => {
    toastRef.current?.show('error', 'AD Error');
  }, []);

  const onAdClose = useCallback(() => {
    toastRef.current?.show('error', 'Not completed, unable to receive rewards');
  }, []);

  const handleOpenAd = () => {
    setOpenAd(false);
  };

  useUpdateEffect(() => {
    if (data?.activity_invitation_reward?.has_reward && !isClaim) {
      setIsClaim(true);
      broadcastingRef.current?.show();
    }
  }, [data, isClaim]);

  return (
    <div className="w-screen h-screen">
      <SpinToast ref={spinToastRef} />
      <Toast ref={toastRef} />

      <GetFreeEnergyModal
        getFreeEnergyModalRef={getFreeEnergyModalRef}
        onClose={handleClose}
        onAfterNavigate={handleAfterNavigate}
      />
      <WatchAdModal
        watchAdModalRef={watchAdModalRef}
        onAdComplete={onAdComplete}
        onAdError={onAdError}
        onAdClose={onAdClose}
        onOpenAd={handleOpenAd}
        currentAd={status?.current_ad_provider_id}
      />
      <Broadcasting
        broadcastingRef={broadcastingRef}
        rewardAmount={data?.activity_invitation_reward?.reward_amount}
      />
      <VolumeModal volumeModal={volumeModal} />

      <div className='w-full h-full fixed flex justify-center items-center left-0 top-0 bg-[url("@/assets/images/game/spin/bg.png")] bg-cover bg-top bg-no-repeat'>
        <div className="relative w-full h-full flex flex-col justify-center items-center">
          <div
            className={clsx(
              'w-full flex flex-col justify-center items-center mb-[20vh]',
              !isApp && 'scale-[0.8]'
            )}
          >
            <Suspense>
              <SpinAnimation ref={spinAnimationRef} />
            </Suspense>
            <div className="relative w-full h-[196px]">
              <img
                className="absolute w-[256px] h-[196px] z-[3] top-[5px] left-[60px]"
                src={bg1}
                alt=""
              />
              <img
                className="absolute w-[288px] h-[145px] z-[2] top-[31px] left-[46px]"
                src={bg2}
                alt=""
              />
              <img
                className="absolute w-[318px] h-[110px] z-[1] top-[48px] left-[33px]"
                src={bg3}
                alt=""
              />
              <img
                className="absolute w-[39px] h-[89px]  top-[22px] left-[5px]"
                src={handle2}
                alt=""
              />

              <div className="absolute top-[5px] left-[60px] gap-y-1 flex flex-col items-center justify-center z-[6]">
                <div className="mt-2 mr-2 w-[190px] flex items-center justify-center bg-[#000] rounded-[18px] shadow-[0px_-2px_0px_1px_#FFDA54,0px_-6px_12px_0px_#FFB53D4D_inset]">
                  <img
                    className="w-[25px] h-[25px]"
                    src={lightLeftIcon}
                    alt=""
                  />
                  <div
                    className={clsx(
                      'flex-1 flex items-center justify-center px-3',
                      !coins.coin && '[visibility:hidden]'
                    )}
                  >
                    <img
                      className="w-[25px] h-[25px]"
                      src={slot[coins.index]}
                      alt=""
                    />
                    <span className="text-2xl font-changa-one italic text-[#fff] drop-shadow-[-1.5px_0px_1.5px_#FFBB20] text-shadow text-shadow-blur-8 text-shadow-[#FFCE3F99]">
                      {formatCurrency(coins.coin)}
                    </span>
                  </div>
                  <img
                    className="w-[25px] h-[25px]"
                    src={lightRightIcon}
                    alt=""
                  />
                </div>
                <div className="flex items-center justify-center ml-[-8px] mt-[5px]">
                  <motion.img
                    animate={controls}
                    className="w-[34px] h-[24px] z-[1]"
                    src={arrowLeftIcon}
                    alt=""
                  />
                  <div className="flex w-[204px] h-[118px] gap-x-1 ml-[-4px] mr-[6px]">
                    <SpinItem
                      key={0}
                      isSpin={isSpins[0]}
                      currentNo={currentNos[0]}
                      col={0}
                    />
                    <SpinItem
                      key={1}
                      isSpin={isSpins[1]}
                      currentNo={currentNos[1]}
                      col={1}
                    />
                    <SpinItem
                      key={2}
                      isSpin={isSpins[2]}
                      currentNo={currentNos[2]}
                      col={2}
                    />
                  </div>
                  <motion.img
                    animate={controls}
                    className="w-[34px] h-[24px]"
                    src={arrowRightIcon}
                    alt=""
                  />
                </div>
              </div>
            </div>

            <div className="relative w-[302px] h-[72px] z-[4]">
              <img
                className="ml-[1px] w-[301px] h-full"
                src={pedestal}
                alt=""
              />
              <div className="absolute w-full h-full top-[-6px] left-0 flex items-center justify-around p-[10px]">
                <div className="relative w-[40px] h-[30px] mb-1">
                  <div className="absolute top-[-18px] left-[28px] bg-[#FF5C01] scale-[0.6]  p-[0px_6px_0px_6px] rounded-[5px_5px_5px_0px]">
                    <span className="font-changa-one leading-[12px] text-[#fff] ">
                      x{multiplier}
                    </span>
                  </div>
                  <img
                    onClick={handleMultiple}
                    className={clsx(
                      'w-[40px] cursor-pointer absolute bottom-0 left-0',
                      isBet ? 'h-[25px]' : 'h-[28px]'
                    )}
                    src={isBet ? betPressedIcon : betIcon}
                    alt=""
                  />
                </div>

                <img
                  onClick={handleSpin}
                  className="w-[118px] h-[48px] cursor-pointer mb-1 mr-1"
                  src={isPressed ? spinBtnPressed : spinBtn}
                  alt=""
                />
                <div className="relative w-[40px] h-[58px] mr-[-5px] flex items-center">
                  <img className=" w-[30px] h-[40px] " src={holes} alt="" />
                  <img
                    className=" absolute top-[-6px] left-[13px] w-[35px] h-[45px]"
                    src={handle}
                    alt=""
                  />
                </div>
              </div>
            </div>
            <EnergyInterval energy={energy} refreshSpinInfo={refreshSpinInfo} />
          </div>
          <div className="absolute w-full left-0 bottom-[35px] flex justify-between items-center z-[5] pointer-events-none">
            <img
              className="w-[110px] h-[144px] object-contain"
              src={plantLeft}
              alt=""
            />
            <img
              className="w-[110px] h-[144px] object-contain"
              src={plantRight}
              alt=""
            />
          </div>
        </div>

        <Header volumeModal={volumeModal} crystal />
      </div>
    </div>
  );
}
